<template>

  <!--Page Title-->
  <section class="page-title bg-color-1 text-center">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-18.png);"></div>
    <div class="auto-container">
      <div class="content-box">
        <h1>Página de error</h1>
        <ul class="bread-crumb clearfix">
          <li><a href="/">Home</a></li>
          <li>Error</li>
        </ul>
      </div>
    </div>
  </section>
  <!--End Page Title-->


  <!-- error-section -->
  <section class="error-section centred">
    <div class="container">
      <div class="content-box">
        <h1>404</h1>
        <h2>¡Vaya, esta página no se encuentra!</h2>
        <div class="text">¿No encuentras lo que necesitas? Tómese un momento y realice una búsqueda a continuación<br /> o comience desde nuestra. <a href="/">página de inicio.</a></div>
      </div>
    </div>
  </section>
  <!-- error-section end -->


</template>
<script>
export default {
  name: 'HomeView',
  setup() {
    return {

    };
  },
}
</script>
