<template>

  <!-- banner-section -->
  <section class="banner-section">
    <div class="pattern-box">
      <div class="pattern-1 wow slideInDown"  v-wow="{ 'animation-name': 'slideInDown','animation-duration': '1500ms', 'animation-delay': '500ms'}"
           style="background-image: url(assets/images/shape/pattern-1.png);"></div>
      <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
    </div>
    <div class="auto-container">
      <div class="row clearfix">
        <div class="col-lg-6 col-md-12 col-sm-12 content-column">
          <div class="content-box wow fadeInUp"  v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1500ms', 'animation-delay': '500ms'}">
            <h1>Dispositivos de Escucha<span class="animation_text_word"></span> Permanente.</h1>
            <p>Conoce en tiempo real las comunicaciones que marcan la agenda en la opinión pública.</p>
            <div class="btn-box">
              <a href="#about" class="theme-btn style-one">Quiero saber más</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 image-column">
          <div class="image-box wow slideInRight" v-wow="{ 'animation-name': 'slideInRight','animation-duration': '1500ms', 'animation-delay': '0ms'}" data-wow-delay="0ms" data-wow-duration="1500ms">
            <figure class="image image-1"><img src="assets/images/banner/banner-1.png" alt=""></figure>
            <figure class="image image-2 float-bob-y"><img src="assets/images/banner/banner-2.png" alt=""></figure>
            <figure class="image image-3 wow slideInDown" v-wow="{ 'animation-name': 'slideInDown','animation-duration': '1500ms', 'animation-delay': '500ms'}"  data-wow-delay="500ms" data-wow-duration="1500ms"><img
                src="assets/images/banner/banner-5.png" alt=""></figure>
            <figure class="image image-4"><img src="assets/images/banner/banner-3.png" alt=""></figure>
            <figure class="image image-5 rotate-me"><img src="assets/images/banner/banner-4.png" alt=""></figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- banner-section end -->


  <!-- feature-section -->
  <section class="feature-section">
    <div class="auto-container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 feature-block">
          <div class="feature-block-one wow flipInY" v-wow="{ 'animation-name': 'flipInY','animation-duration': '1500ms', 'animation-delay': 'oms'}" data-wow-delay="0ms" data-wow-duration="1500ms">
            <div class="inner-box js-tilt">
              <div class="icon-box">
                <div class="anim-icon">
                  <span class="icon icon-1"></span>
                  <span class="icon icon-2"></span>
                  <span class="icon icon-3"></span>
                </div>
                <i class="flaticon-tools-and-utensils"></i>
              </div>
              <h3><a href="/">Dispositivos de Redes Sociales</a></h3>
              <p>Conoce a través de nuestros Mapas de Agentes, Medios, Semántico y Regulador los mensajes que recorren las redes sociales.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 feature-block">
          <div class="feature-block-one wow flipInY" v-wow="{ 'animation-name': 'flipInY','animation-duration': '1500ms', 'animation-delay': '300ms'}"  data-wow-delay="300ms" data-wow-duration="1500ms">
            <div class="inner-box js-tilt">
              <div class="icon-box">
                <div class="anim-icon">
                  <span class="icon icon-1"></span>
                  <span class="icon icon-2"></span>
                  <span class="icon icon-3"></span>
                </div>
                <i class="flaticon-checked"></i>
              </div>
              <h3><a href="/">Dispositivos de Comunidades</a></h3>
              <p>Identifica liderazgos locales y las causas que impulsan a través de informes Thick Data.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 feature-block">
          <div class="feature-block-one wow flipInY" v-wow="{ 'animation-name': 'flipInY','animation-duration': '1500ms', 'animation-delay': '600ms'}" data-wow-delay="600ms" data-wow-duration="1500ms">
            <div class="inner-box js-tilt">
              <div class="icon-box">
                <div class="anim-icon">
                  <span class="icon icon-1"></span>
                  <span class="icon icon-2"></span>
                  <span class="icon icon-3"></span>
                </div>
                <i class="flaticon-pie-chart"></i>
              </div>
              <h3><a href="/">Dispositivos de Políticas Públicas</a></h3>
              <p>Evalúa los contenidos de proyectos de ley para estimar probabilidades de aprobación y rechazo.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- feature-section end -->


  <!-- about-section -->
  <section id="about" class="about-section">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="col-lg-6 col-md-6 col-sm-12 image-column">
          <div id="image_block_01">
            <div class="image-box wow slideInLeft" v-wow="{ 'animation-name': 'slideInLeft','animation-duration': '1500ms', 'animation-delay': '0ms'}" data-wow-delay="0ms" data-wow-duration="1500ms">
              <figure class="image js-tilt"><img src="assets/images/resource/illustration-2.png" alt=""></figure>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 content-column">
          <div id="content_block_01">
            <div class="content-box">
              <div class="sec-title wow slideInUp" v-wow="{ 'animation-name': 'slideInUp','animation-duration': '1500ms', 'animation-delay': '0ms'}" data-wow-delay="00ms" data-wow-duration="1500ms">

                <h2>Sistematizamos data abierta para la toma de decisiones</h2>
              </div>

              <ul class="list-item clearfix wow slideInUp" v-wow="{ 'animation-name': 'slideInUp','animation-duration': '1500ms', 'animation-delay': '600ms'}" data-wow-delay="600ms" data-wow-duration="1500ms">
                <li><strong>¿Sabes lo que dice la opinión pública de tu empresa?</strong>
                  <div class="text">La mayoría de los estudios de opinión pública tienen problemas metodológicos de representatividad y quedan desactualizados en pocas semanas.</div></li>
                <li><strong>¿Sabes cuáles son las causas que movilizan a la opinión pública hoy?</strong>
                <div class="text">Estudiamos las comunicaciones de medios y comunidades digitales para identificar de las causas que movilizan a las personas y marcan la agenda de la discusión pública”.</div></li>
                <li><strong>¿Conoces la discusión legislativa de las políticas públicas que impactan tu negocio?</strong>
                  <div class="text">Te ayudaremos a seguir la tramitación de leyes en el Congreso y a desplegar estrategias de incidencia con el regulador que le ayudarán a tomar mejores decisiones.</div></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- about-section end -->


  <!-- process-section -->
  <section class="process-section bg-color-1">
    <div class="pattern-box">
      <div class="pattern-1 wow slideInDown animated" v-wow="{ 'animation-name': 'slideInDown','animation-duration': '1500ms', 'animation-delay': '500ms'}" data-wow-delay="500ms" data-wow-duration="1500ms"
           style="background-image: url(assets/images/shape/pattern-3.png)"></div>
    </div>
    <div class="auto-container">
      <div class="row clearfix">
        <div class="col-lg-6 col-md-6 col-sm-12 content-column">
          <div id="content_block_02">
            <div class="content-box">
              <div class="sec-title wow slideInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                <p>Cómo funciona</p>
                <h2>¿Qué hacemos en Hebra Analytics?</h2>
              </div>
              <div class="text wow slideInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                <p>Combinamos la ciencia de datos y ciencias sociales para identificar los temas emergentes, líderes de opinión y eventos que marcan la discusión pública.</p>
              </div>
              <div class="inner-box wow slideInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                <div class="single-item">
                  <div class="count-box">1</div>
                  <h3><a href="/">Data abierta.</a></h3>
                  <div class="text">Capturamos información de distintas plataformas de redes sociales y medios de comunicación para realizar un lectura estratégica."</div>
                </div>
                <div class="single-item">
                  <div class="count-box">2</div>
                  <h3><a href="/">Inteligencia Artificial.</a></h3>
                  <div class="text">Utilizamos herramientas de Inteligencia Artificial para el procesamiento de datos.
                  </div>
                </div>
                <div class="single-item">
                  <div class="count-box">3</div>
                  <h3><a href="/">Seguimiento Legislativo</a></h3>
                  <div class="text">Extraemos información de la tramitación de proyectos de ley y votaciones de parlamentarios para monitorear la implementación de políticas públicas.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 image-column">
          <div id="image_block_02">
            <div class="image-box wow slideInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
              <figure class="image js-tilt"><img src="assets/images/resource/illustration-3.png" alt=""></figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- process-section end -->


  <!-- service-section -->
  <section class="service-section">
    <div class="auto-container">
      <div class="sec-title text-center">
        <p>Servicios</p>
        <h2>Productos y Servicios</h2>
      </div>
      <div class="row clearfix">

        <div class="col-lg-4 col-md-6 col-sm-12 service-block">
          <div class="service-block-one wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
            <div class="inner-box">
              <div class="icon-box"><i class="flaticon-machine-learning"></i></div>
              <h3><a href="/">Dashboard RRSS</a></h3>
              <p>Nuestra plataforma ilustra en tiempo real datos de la discusión pública seleccionados según necesidades prestablecidas. Usando técnicas avanzadas de procesamiento del lenguaje natural identificamos tendencias y perspectivas sobre cualquier área de interés que tengas.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 service-block">
          <div class="service-block-one wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1500ms">
            <div class="inner-box">
              <div class="icon-box"><i class="flaticon-data-analytics"></i></div>
              <h3><a href="/">Informes Thick Data</a></h3>
              <p>Usando nuestra metodología única de triangulación de fuentes de información, podemos comprender la cultura y las actitudes de las comunidades, especialmente las actitudes sociales. Combinando técnicas tradicionales de  etnografía con nuevas tecnologías basadas en inteligencia artificial y machine learning podemos identificar las causas que movilizan a las personas.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 service-block">
          <div class="service-block-one wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms">
            <div class="inner-box">
              <div class="icon-box"><i class="flaticon-file"></i></div>
              <h3><a href="/">Big Data</a></h3>
              <p>Nuestros algoritmos pueden detectar patrones en las redes sociales, en medios de comunicación y en tu propia información o bases de datos. Los procesamos y organizamos de forma comprensible para la toma de decisiones.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 service-block">
          <div class="service-block-one wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
            <div class="inner-box">
              <div class="icon-box"><i class="flaticon-laptop"></i></div>
              <h3><a href="/">Políticas Públicas</a></h3>
              <p>Hacemos seguimiento de los debates políticos y legislativos y de las votaciones parlamentarias para identificar tendencias y pautas. Nuestros dispositivos procesan las políticas públicas que impactan en tu negocio. Nuestra tecnología para analizar grandes cantidades de información sobre eventos políticos como votaciones, debates e indicaciones ayudan a desplegar estrategias de incidencia para promover la implementación de mejores políticas públicas.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 service-block">
          <div class="service-block-one wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
            <div class="inner-box">
              <div class="icon-box"><i class="flaticon-data-integration"></i></div>
              <h3><a href="/">Advocacy</a></h3>
              <p>Prestamos servicios de asesoría estratégica para generar y desplegar estrategias de advocacy corporativo.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 service-block">
          <div class="service-block-one wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
            <div class="inner-box">
              <div class="icon-box"><i class="flaticon-robot"></i></div>
              <h3><a href="/">Servicios Personalizados</a></h3>
              <p>Somos expertos en utilizar nuestra tecnología y metodologías de escucha para desarrollar nuevas soluciones que satisfagan tus necesidades específicas. Nos interesa establecer una relación sólida y duradera con tu organización.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- service-section end -->


</template>
<script>
import {jQuery} from "../plugins/jquery";

export default {
  name: 'HomeView',
  setup() {
    return {};
  },
  mounted() {
    //this.animateText();
  },
  methods: {
    animateText(){
      let animateSpan = jQuery('.animation_text_word');
      animateSpan.typed({
        strings: ["Permanente", "Permanente", "Permanente"],
        loop: true,
        startDelay: 1e3,
        backDelay: 3e3
      });
    },
    onHoverthreeDmovement(){
      var tiltBlock = jQuery('.js-tilt');
      if(tiltBlock.length) {
        jQuery('.js-tilt').tilt({
          maxTilt: 20,
          perspective:700,
          glare: true,
          maxGlare: 0
        })
      }
    }
  }
}
</script>
