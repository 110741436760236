import {createRouter, createWebHistory} from 'vue-router'
//import HomeView from './pages/Home.vue';
import HomeDevView from './pages/HomeDev.vue';
import ContactoView from './pages/Contacto';
import NotFoundComponent from './pages/NotFound.vue';
const routes =  [
    {
        path: '/',
        component: HomeDevView
    },
    {
        path: '/dev',
        component: HomeDevView
    },
    {
        path: '/contacto',
        component: ContactoView
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFoundComponent
    }
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
