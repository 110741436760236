import ContactService from '../services/contact.service';
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };
export const contact = {
    namespaced: true,
    state: initialState,
    actions: {
        register({ commit },contact) {
            console.log(contact);
            return ContactService.register(contact).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailed');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        registerFailed(){

        }
    }
};
