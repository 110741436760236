import axios from "axios";
let HOST = 'https://bxf6a9c088.execute-api.us-east-1.amazonaws.com/prod';
if (process.env.NODE_ENV === 'development') {
    HOST = 'http://localhost:3000/prod';
}
const instance = axios.create({
    baseURL: HOST,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 30000
});
export default instance;
