<template>


  <!--Page Title-->
  <section class="page-title bg-color-1 text-center">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-18.png);"></div>
    <div class="auto-container">
      <div class="content-box">
        <h1>Contacto</h1>
        <ul class="bread-crumb clearfix">
          <li><a href="/">Home</a></li>
          <li>Contacto</li>
        </ul>
      </div>
    </div>
  </section>
  <!--End Page Title-->

  <!-- contact-section -->
  <section class="contact-section">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 form-column mt-3">
          <div class="form-inner">
            <form method="post" @submit.prevent="handleRegister" id="contact-form" class="default-form">
              <div class="row clearfix">
                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="text" name="username" placeholder="Nombre *" v-model="form.username" required="">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="email" name="email" placeholder="Email *"  v-model="form.email" required="">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="text" name="phone" placeholder="Telefono"  v-model="form.phone">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input type="text" name="subject" placeholder="Asunto del mensaje *"  v-model="form.subject" required="">
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                  <textarea name="message" placeholder="Mensaje..." v-model="form.message"></textarea>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                  <div v-if="messageErr" class="alert alert-danger" role="alert">
                    {{ messageErr }}
                  </div>
                  <div v-if="message" class="alert alert-info" role="alert">
                    {{ message }}
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                  <button class="theme-btn style-one" v-if="!loading" type="submit" name="submit-form">Enviar Mensaje</button>
                  <button class="theme-btn style-one" v-else disabled="disabled" type="submit" name="submit-form">Enviando...</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- contact-section end -->

</template>
<script>
import { load } from 'recaptcha-v3';

export default {
  name: 'ContactView',
  data() {
    return {
      recaptcha: null,
      message: null,
      messageErr: null,
      loading: false,
      form: {
        username: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
        recaptcha: null
      }
    };
  },
  mounted() {
    this.asyncFunction();
  },
  methods: {
    async asyncFunction() {
      this.recaptcha = await load('6LeC6PolAAAAAKOpGSDFtrt1ppYRPYFPE0kazfoO',{ autoHideBadge: true});
    },
    async handleRegister() {
      this.form.recaptcha = await this.recaptcha.execute("contact");
      this.message = "";
      this.messageErr = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("contact/register", this.form).then(
          (data) => {
            console.log(data);
            if(data.success) {
              this.message = data.message;
            } else {
              this.messageErr = data.message;
            }
            this.loading = false;
          },
          (error) => {
            console.log(error)
            this.messageErr = "Error al enviar la solicitud de contacto";
            this.loading = false;
          }
      );
    }
  }
}
</script>
