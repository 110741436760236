import { createStore } from 'vuex'
import { contact } from "./contact.module";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    contact
  }
})
