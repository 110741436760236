<template>

  <!-- preloader -->
  <div class="preloader"></div>
  <!-- preloader -->

  <!-- main header -->
  <header class="main-header">
    <div class="outer-container">
      <div class="header-upper clearfix">
        <div class="outer-box pull-left">
          <div class="logo-box pull-left">
            <figure class="logo"><a href="/"><img src="assets/images/logo.png" alt=""></a></figure>
          </div>
          <div class="menu-area pull-left">
            <!--Mobile Navigation Toggler-->
            <div class="mobile-nav-toggler">
              <i class="icon-bar"></i>
              <i class="icon-bar"></i>
              <i class="icon-bar"></i>
            </div>
            <nav class="main-menu navbar-expand-md navbar-light">
              <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                <ul class="navigation clearfix">
                  <li class="current dropdown"><a href="/">Home</a></li>
                  <li><a href="/contacto">Contacto</a></li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="menu-right-content pull-right">
          <div class="btn-box"><a href="/">Login</a></div>
        </div>
      </div>
    </div>

    <!--sticky Header-->
    <div class="sticky-header">
      <div class="container clearfix">
        <figure class="logo-box"><a href="/"><img src="assets/images/small-logo.png" alt=""></a></figure>
        <div class="menu-area">
          <nav class="main-menu clearfix">
            <!--Keep This Empty / Menu will come through Javascript-->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- main-header end -->

  <!-- Mobile Menu  -->
  <div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><i class="fas fa-times"></i></div>

    <nav class="menu-box">
      <div class="nav-logo"><a href="/"><img src="assets/images/logo-2.png" alt="" title=""></a></div>
      <div class="menu-outer"><!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--></div>
      <div class="contact-info">
        <h4>Informacion de contacto</h4>
        <ul>
          <li><a href="mailto:info@example.com">contacto@hebranalytics.cl</a></li>
        </ul>
      </div>
      <div class="social-links">
        <ul class="clearfix">
          <li><a href="/"><span class="fab fa-twitter"></span></a></li>
          <li><a href="/"><span class="fab fa-facebook-square"></span></a></li>
          <li><a href="/"><span class="fab fa-pinterest-p"></span></a></li>
          <li><a href="/"><span class="fab fa-instagram"></span></a></li>
          <li><a href="/"><span class="fab fa-youtube"></span></a></li>
        </ul>
      </div>
    </nav>
  </div><!-- End Mobile Menu -->

  <router-view/>

  <!-- main-footer -->
  <footer class="main-footer">
    <div class="auto-container">
      <div class="footer-top">
        <div class="widget-section wow fadeInUp" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1500ms', 'animation-delay': 'oms'}" data-wow-delay="0ms" data-wow-duration="1500ms">
          <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div class="footer-widget logo-widget">
                <figure class="footer-logo"><a href="/"><img src="assets/images/footer-logo.png" alt=""></a></figure>
                <div class="text">
                  <p></p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div class="footer-widget links-widget">
                <div class="widget-title">
                  <h3>Enlace rápido</h3>
                </div>
                <div class="widget-content">
                  <ul>
                    <li><a href="/contacto">Contacto</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div class="footer-widget links-widget">
                <div class="widget-title">
                  <h3>Servicios</h3>
                </div>
                <div class="widget-content">
                  <ul>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div class="footer-widget contact-widget">
                <div class="widget-title">
                  <h3>Datos de contacto</h3>
                </div>
                <div class="widget-content">
                  <ul>

                    <li><a href="mailto:info@example.com">contacto@hebranalytics.cl</a></li>
                  </ul>
                </div>
                <ul class="social-links clearfix">
                  <li><a href="/"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="/"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="/"><i class="fab fa-vimeo-v"></i></a></li>
                  <li><a href="/"><i class="fab fa-linkedin-in"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom clearfix">
        <div class="copyright pull-left">
          <p><a href="/">Hebra Analytics</a> &copy; 2023 Todos los derechos reservados</p>
        </div>
        <ul class="footer-nav pull-right">
          <li><a href="/">Términos de servicio</a></li>
          <li><a href="/">Política de privacidad</a></li>
        </ul>
      </div>
    </div>
  </footer>
  <!-- main-footer end -->

  <!--Scroll to top-->
  <button class="scroll-top scroll-to-target" data-target="html">
    <span class="fa fa-arrow-up"></span>
  </button>
  <!-- built files will be auto injected -->

</template>
<script>
import {jQuery} from "./plugins/jquery";
export default {
  name: 'App',
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.headerStyle();
    this.handlePreloader();
    this.scroll();
    this.animateText();
    this.menu();
  },
  methods: {
    menu(){
      if(jQuery('.main-header li.dropdown ul').length){
        jQuery('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
      }
      //Mobile Nav Hide Show
      if(jQuery('.mobile-menu').length){

        jQuery('.mobile-menu .menu-box').mCustomScrollbar();

        var mobileMenuContent = jQuery('.main-header .menu-area .main-menu').html();
        jQuery('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);
        jQuery('.sticky-header .main-menu').append(mobileMenuContent);

        //Dropdown Button
        jQuery('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
          jQuery(this).toggleClass('open');
          jQuery(this).prev('ul').slideToggle(500);
        });
        //Menu Toggle Btn
        jQuery('.mobile-nav-toggler').on('click', function() {
          jQuery('body').addClass('mobile-menu-visible');
        });

        //Menu Toggle Btn
        jQuery('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function() {
          jQuery('body').removeClass('mobile-menu-visible');
        });
      }
    },
    headerStyle(){
      if(jQuery('.main-header').length){
        let windowpos = jQuery(window).scrollTop();
        let siteHeader = jQuery('.main-header');
        let scrollLink = jQuery('.scroll-top');
        if (windowpos >= 110) {
          siteHeader.addClass('fixed-header');
          scrollLink.addClass('open');
        } else {
          siteHeader.removeClass('fixed-header');
          scrollLink.removeClass('open');
        }
      }
    },
    scroll(){
      let left = this;
      if(jQuery('.scroll-to-target').length){
        jQuery(".scroll-to-target").on('click', function() {
          let target = jQuery(this).attr('data-target');
          jQuery('html, body').animate({
            scrollTop: jQuery(target).offset().top
          }, 1000);
        });
      }
      jQuery(window).on('scroll', function() {
        left.headerStyle();
      });
    },
    handlePreloader(){
      if(jQuery('.preloader').length){
        jQuery('.preloader').delay(200).fadeOut(500);
      }
    },
    animateText(){
      let animateSpan = jQuery('.animation_text_word');
      animateSpan.typed({
        strings: ["Detox", "Detox", "Detox"],
        loop: true,
        startDelay: 1e3,
        backDelay: 3e3
      });
    }
  }
}
</script>
